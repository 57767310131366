import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Snackbar from '@material-ui/core/Snackbar';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import NoSleep from '@uriopass/nosleep.js';

import Modal from '@material-ui/core/Modal';
import { PhoneModal } from './PhoneModal'
import parsePhoneNumber from 'libphonenumber-js'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
}));

function ToList(props) {
  const noSleep = new NoSleep();
  if (props.user == null) {
    window.location.href = '/';
  }
  const [open, setOpen] = useState(false);
  const [to, setTo] = useState(null);
  const [list, setList] = useState([]);
  const [token, setToken] = useState(null);
  const [initialized, setInitialized] = useState(false);
  const [message, setMessage] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [trackingId, setTrackingId] = useState(null);

  useEffect(() => {
    const uri = window.location.href;
    const queryParams = uri.split('?');
    let trackId;
    if (queryParams.length > 1) {
      const data = queryParams[1].split('&');
      for(let i =  0, l = data.length; i < l; i++) {
	const q = data[i];
        const keyVal = q.split('=');
	if (keyVal[0] == 'media') {
          trackId = keyVal[1];
	}
      }
    }
    if (trackId) { setTrackingId(trackId); }
    fetchList();
    fetchToken();
  }, []);

  useEffect(() => {
    if (to) {
      setOpen(true);
      connect();
    } else {
      setOpen(false);
      if (initialized) {
        disconnect();
      }
    }
  }, [to]);

  const classes = useStyles();

  useEffect(() => {
    if (token) {
      setup();
    }
  }, [token]);

  const setup = async () => {
    if (initialized) {
      Twilio.Device.setup(token, {
        edge: 'tokyo',
        allowIncomingWhileBusy: false,
        closeProtection: '通話が中断されてしまいます。本当にこのページを離れますか？',
      });
    } else {
      Twilio.Device.on('ready', () => {
        setInitialized(true);
        Twilio.Device.on('cancel', () => {
          console.log('canceled');
        });
        Twilio.Device.on('offline', () => {
          flashMessage('オフラインになりましたので電話に接続できませんでした');
        });
        Twilio.Device.on('error', () => {
          flashMessage('エラーが発生しました。電話に接続できませんでした');
        });
        Twilio.Device.on('incoming', handleIncomingCall);
        Twilio.Device.on('disconnect', () => {
          noSleep.disable();
          setTo(null);
          flashMessage('電話を切断しました');
        });
        Twilio.Device.on('connect', handleConnect);
        console.log('ready');
      });
      Twilio.Device.setup(token, {edge: 'tokyo'});
    }
  };

  const handleIncomingCall = async (call) => {};

  const handleConnect = async (conn) => {
    conn.on('warning', (name) => {
      switch(name) {
        case 'high-jitter':
        case 'high-rtt':
        case 'low-mos':
        case 'high-packet-loss':
          flashMessage('ネットワークが不安定です');
        break;
        case 'constant-audio-input-level':
          flashMessage('マイクが検出できません');
        break;
        case 'constant-audio-output-level':
          flashMessage('スピーカーが検出できません');
        break;
        case 'ice-connectivity-lost':
          flashMessage('通信が切断されました。画面を再読み込みしてください');
        break;
      }
    });
  };

  const flashMessage = async (msg) => {
    setMessage(msg);
    setOpenSnackbar(true);
  };

  const disconnect = async () => {
    Twilio.Device.disconnectAll();
    setTo(null);
  };

  const connect = async () => {
    noSleep.enable();
    flashMessage('接続しています');
    Twilio.Device.connect({
      user: props.user.name,
      trackingId: trackingId,
      connect_to: to.id,
    });
  };

  const fetchToken = async () => {
    const res = await axios.get('/twilio/token')
    setToken(res.data.token);
  };

  const handleClick = (data) => {
    setTo(data);
  };

  const handleInfo = () => {
  };

  const handleClose = () => {
  };

  const fetchList = async () => {
    const res = await axios.get('/api/v1/list.json');
    const newList = res.data;
    setList(newList);
  };

  return (<>
    <AppBar position="static">
        <Toolbar>
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            無料通話
          </Typography>
        </Toolbar>
      </AppBar>
    <List className={classes.root}>
      {list.map((to) => {
	let callToNumber = to.to;
	try {
          const phoneNumber = parsePhoneNumber(to.to, 'JP');
	  callToNumber = phoneNumber.formatNational();
	} catch (e) {

	}
        return (<ListItem key={`to-${to.id}`} alignItems="flex-start" onClick={() => {handleClick(to);}}>
          <ListItemAvatar>
            <Avatar alt={to.name} src={to.image_url} />
          </ListItemAvatar>
          <ListItemText
            primary={to.name}
            secondary={
              <React.Fragment>
                <Typography
                  component="span"
                  variant="body2"
                  className={classes.inline}
                  color="textPrimary"
                >
                {callToNumber}
                </Typography>
              </React.Fragment>
            }
          />
        </ListItem>);
        })}
      <Divider variant="inset" component="li" />
      </List>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className="centered">
        {to &&
          <>
          <PhoneModal 
            {...props} 
            to={to} 
            flashMessage={flashMessage}
            setTo={setTo} 
            disconnect={disconnect} />
          </>
        }
        </div>
      </Modal>
      <Snackbar
        anchorOrigin={{vertical:'bottom', horizontal:'center'}}
        open={openSnackbar}
        onClose={()=>{setOpenSnackbar(false);}}
        message={message}
        key="bottomcenter"
      />
    </>
  );
}

export{ToList};
