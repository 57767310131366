/*
 * 現在利用されていないscriptです
 * */
import React, { useState, useEffect } from 'react'
import parsePhoneNumber from 'libphonenumber-js'
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import ListSubheader from '@material-ui/core/ListSubheader';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';

import Modal from '@material-ui/core/Modal';
import { PhoneModal } from './PhoneModal'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.paper,
  },
  gridList: {
    width: '70%',
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  avatar: {
    backgroundColor: 'red',
  },
}));

const tileData = [
  {
    img: '/images/sasaki1.jpg',
    title: '佐々木携帯',
    open: '24時間',
    number: '+818050040463',
  },
  {
    img: '/images/sasaki1.jpg',
    title: '佐々木予備',
    number: '+819099584950',
    open: '24時間'
  },
  //{
  //  img: '/images/tsukuroi.jpg',
  //  title: 'つくろい東京ファンド',
  //  number: '+81359428086',
  //  open: '9:00 - 17:00'
  //},
  //{
  //  img: '/images/inaba.jpg',
  //  title: '稲葉携帯',
  //  number: '+819061598787',
  //  open: '9:00 - 17:00'
  //},
  //{
  //  img: '/images/seto.png',
  //  title: '瀬戸携帯',
  //  number: '+819014373502',
  //  open: '9:00 - 17:00'
  //},
];

function Phone(props) {
  const [open, setOpen] = useState(false);
  const [to, setTo] = useState(null);

  useEffect(() => {
    if (to) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [to]);

  const classes = useStyles();

  const handleClick = (data) => {
    setTo(data);
  };

  const handleInfo = () => {
  };

  const handleClose = () => {
console.log('close');
  };

  return <>
    <div className={classes.root}>
      <GridList cellHeight={300} className={classes.gridList}>
        <GridListTile key="Subheader" cols={2} rows={2} style={{ height: 'auto' }}>
          <ListSubheader component="div">どこでも公衆電話</ListSubheader>
        </GridListTile>
        {tileData.map((tile, idx) => (
          <GridListTile key={idx} cols={2} rows={2} onClick={() => {
            handleClick(tile);
          }} style={{cursor:'pointer'}}>
            <img src={tile.img} alt={tile.title} />
            <GridListTileBar
              title={tile.title}
              subtitle={<span>対応時間: {tile.open}</span>}
              actionIcon={
                <IconButton onClick={() => {handleInfo(tile);}} aria-label={`info about ${tile.title}`} className={classes.icon}>
                  <InfoIcon />
                </IconButton>
              }
            />
          </GridListTile>
        ))}
      </GridList>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div className="centered">
        {to &&
          <>
          <PhoneModal {...props} to={to} setTo={setTo} />
          </>
        }
        </div>
      </Modal>
    </div>
  </>

}

export{Phone};
