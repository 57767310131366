import React from 'react'
import ReactDOM from 'react-dom'

import { App } from './App'

document.addEventListener('DOMContentLoaded', () => {
  const div = document.createElement('div');
  ReactDOM.render(
    <App />,
    document.body.appendChild(div),
  )
});
