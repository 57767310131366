import React, {useState, useEffect} from 'react'
import { useHistory } from "react-router-dom";
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import axios from 'axios';

function Login(props) {
  let history = useHistory();
  const [identity, setIdentity] = useState('');
  const [helperText, setHelperText] = useState('');

  useEffect(() => {
    // auto login
    const uri = window.location.href;
    const queryParams = uri.split('?');
    let trackingId;
    if (queryParams.length > 1) {
      const data = queryParams[1].split('&');
      for(let i =  0, l = data.length; i < l; i++) {
	const q = data[i];
        const keyVal = q.split('=');
	if (keyVal[0] == 'media') {
          trackingId = keyVal[1];
	}
      }
    }
    const uriData = uri.split('#')[1].split('/').filter((n) => {return n;});
    if (uriData.length >= 1) {
      if (uriData[0].match(/\?/)) {
        handleLogin(uriData[0].split('?')[0], trackingId);
      } else {
        handleLogin(uriData[0], trackingId);
      }
    }
  }, []);

  const handleLogin = async (id, trackingId) => {
    const identityToPost = id != null ? id : identity;
    const autoLogin = id != null ? true : false;
    try {
      const res = await axios.post('/api/v1/guest/login', {token: identityToPost})
      if (res.data) {
        props.setUser(res.data);
	if (trackingId) {
          history.push(`/phone?media=${trackingId}`);
        } else {
          history.push('/phone');
        }
      } else if (autoLogin) {
        history.push('/');
      } else {
        setIdentity('');
        setHelperText('IDに誤りがあります');
      }
    } catch(e) {
      if (autoLogin) {
        history.push('/');
      } else {
        setIdentity('');
        setHelperText('IDに誤りがあります');
      }
    }
  };

  return (<div className="outer-container">
    <div className="centered-content">
      <img src="/images/tsunadenweb.jpg" className="logo" />
      <div className="form">
      <TextField 
        id="identity" 
        label="IDを入力" 
        variant="outlined" 
        margin="normal" 
        style={{width:'90%'}} 
        value={identity}
        helperText={helperText}
        onChange={(e) => {setIdentity(e.target.value);}}
      />
      <Button variant="contained" color="primary" style={{marginTop:'20px'}} onClick={() => {handleLogin()}}>
        ログイン
      </Button>
      </div>
    </div>
  </div>);
}

export{Login};
