import React, {useState, useEffect} from 'react'
import {
  HashRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import { Phone } from './containers/Phone';
import { ToList } from './containers/Phone/ToList';
import { Login } from './containers/Login';

function App(props) {
  const [user, setUser] = useState(null);
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Login setUser={setUser} />
        </Route>
        <Route path="/phone">
          <ToList user={user} />
        </Route>
	<Route>
          <Login setUser={setUser} />
	</Route>
      </Switch>
    </Router>
  );
}

export{App};
