import React, {useState, useEffect} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import CallIcon from '@material-ui/icons/Call';
import CallEndIcon from '@material-ui/icons/CallEnd';
import Avatar from '@material-ui/core/Avatar';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import PhoneIcon from '@material-ui/icons/Phone';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

const useStyles = makeStyles((theme) => ({
  media: {
    height: 350,
    backgroundSize: 'auto',
  },
  cardHeader: {
    backgroundColor: '#E0E0E0',
  }
}));
function PhoneModal(props) {
  const [time, setTime] = useState("00:00:00");
  const classes = useStyles();

  useEffect(() => {
  }, []);

  return (
<Card>
  <CardHeader
    className={classes.cardHeader}
    avatar={
      <Avatar aria-label="recipe" className={classes.avatar} src={props.to.image_url} />
    }
    title={props.to.name}
    subheader={`受付 ${props.to.opening_hour}`}
  />
 <CardMedia
   className={classes.media}
   image={props.to.image_url}
   title={props.to.name}
 />
 <CardContent className={classes.cardHeader}>
   <Typography variant="body2" color="textSecondary" component="p">
     <IconButton color="secondary" aria-label="upload picture" component="span" 
       onClick={() => {
        if (confirm('本当に電話を中断しますか？')) {
          props.setTo(null);
        }
     }}>
       <CallEndIcon />
     </IconButton>
   </Typography>
 </CardContent>
</Card>
  );
}

export{PhoneModal};
